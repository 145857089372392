<template>
    <header :class="$route.meta.fullHeader ? 'site-header header-full-width' : 'site-header'">
        <nav v-show="navShow" :class="'navbar '+($route.meta.fullHeader ? 'nav-full-width' : 'mid-center')">
            <router-link to="/" class="section logo-container">
                <img class="logo" src="../assets/logo.png" alt="">
            </router-link>
            <div class="section menu">
                <span class="menu-item">
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            {{ $t('Components.Header.Nav1') }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
<!--                            <router-link class="drop-item" to="/webs">-->
<!--                                <el-dropdown-item>-->
<!--                                    {{ $t('UI.components.header.menu_1_1') }}-->
<!--                                </el-dropdown-item>-->
<!--                            </router-link>-->
                            <router-link class="drop-item" to="/pods">
                                <el-dropdown-item>
                                    {{ $t('Components.Header.Nav1_1') }}
                                </el-dropdown-item>
                            </router-link>
                            <router-link class="drop-item" to="/photobookai">
                                <el-dropdown-item>
                                    {{ $t('Components.Header.Nav1_2') }}
                                </el-dropdown-item>
                            </router-link>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <router-link to="/downloads" class="menu-item">{{ $t('Components.Header.Nav3') }}</router-link>
                <router-link to="/about" class="menu-item">{{ $t('Components.Header.Nav4') }}</router-link>
            </div>
            <div class="section float-right">
                <span class="menu-item menu-item-mid">
                    <el-dropdown @command="languageChange">
                        <span class="el-dropdown-link">
                            {{ $t('Language') }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh_CN">简体中文</el-dropdown-item>
                            <el-dropdown-item command="en">English</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
            </div>
            <div class="section float-right">
                <span v-if="! loginBtn">
<!--                    <span v-if="$route.meta.displayUserInfo" class="menu-item">-->
<!--                        {{ corpName }}-->
<!--                    </span>-->
                    <span class="menu-item">
                        <router-link to="/console">
                            {{ this.$i18n.t('Components.Header.Nav5') }}
                        </router-link>
                    </span>
                    <span class="menu-item">
                        <el-dropdown @command="userCommand">
                            <span class="el-dropdown-link">
                                <i class="el-icon-user-solid"></i>
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
<!--                                <el-dropdown-item command="a">{{ corpname }}</el-dropdown-item>-->
                                <el-dropdown-item command="a">{{ corpName }}</el-dropdown-item>
                                <el-dropdown-item command="b">{{ username }}</el-dropdown-item>
                                <el-dropdown-item command="signOut"><i style="color: #F56C6C" class="el-icon-switch-button"></i> {{ $t('Components.Header.ActionSignOut') }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
                </span>
                <span v-else>
<!--                    <router-link to="/signin">-->
<!--                        <el-button type="primary" size="small" plain="">{{ $t('Components.Header.ActionSignIn') }}</el-button>-->
<!--                    </router-link>-->
                </span>
            </div>
        </nav>
    </header>
</template>

<script>

    export default {
        props: {},
        data () {
            return {
                // navClass:
                // username: window.localStorage.getItem('username'),
                // corpname: window.localStorage.getItem('corpname'),
                // // isLogin: window.localStorage.getItem('isLogin'),
                // isLogin: window.localStorage.getItem('isLogin') ? window.localStorage.getItem('isLogin') : 'No',

                username: '',
                corpName: '',
                loginBtn: false,
                navShow: false,
            }
        },
        created () {
            setTimeout(function () {
                this.navShow = true
            }, 500)
        },
        updated () {
            this.navShow = true
        },
        watch: {
            $route() {
                if (localStorage.getItem('userInfo')) {
                    const u = JSON.parse(localStorage.getItem('userInfo'))
                    this.username = u.username
                    this.corpName = u.corpName
                }
                this.loginBtn = ! localStorage.getItem('userInfo')
            }
        },
        mounted () {
            // if (localStorage.getItem('loggedIn')) {
            //     this.loggedIn = localStorage.getItem('loggedIn')
            // }
            // if (localStorage.getItem('userInfo')) {
            //     const u = JSON.parse(localStorage.getItem('userInfo'))
            //     this.username = u.username
            //     this.corpName = u.corpName
            // }
            this.loginBtn = ! localStorage.getItem('userInfo')
            // console.log(this.isLogin)
        },
        computed: {},
        methods: {
            languageChange (locale) {
                window.localStorage.setItem('locale', locale)
                window.location.reload()
                // this.$i18n.locale = locale
                // this.$metaAction.titleI18n(locale)
            },
            userCommand (command) {
                if (command === 'signOut') {
                    this.$axios.get('/signOut', {})
                        .then(res => {
                            // window.location.href = '/'
                            // window.localStorage.setItem('isLogin', 'No')
                            localStorage.removeItem('userInfo')
                            // this.$router.push({path: '/'})
                            // this.$cookie.remove('unk')
                            window.location.href = '/'
                        }, err => {})
                } else {
                    if (this.$router.currentRoute.path !== '/console/baseinfo') {
                        this.$router.push({ path: '/console/baseinfo' })
                    }
                }
            }
        },
        components: {},
    }
</script>

<style lang="scss">

</style>