<template>
    <div :class="$route.meta.fullHeader ? 'main-wrapper app-no-scroll' : 'main-wrapper'" id="app">
        <Header></Header>
        <router-view/>
        <Footer v-if="$route.meta.footer"></Footer>
    </div>
</template>

<script>
    import Header from './components/Header'
    import Footer from './components/Footer'

    export default {
        data () {
            return {
            }
        },
        mounted () {
        },
        components: {
            Header,
            Footer,
        },
    }
</script>

<style lang="scss">

</style>
