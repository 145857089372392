<template>
    <footer class="site-footer">
        <div class="footer">
            <el-row>
                <el-col :span="5">
                    <el-link class="footer-title" :underline="false">产品与服务</el-link>
                    <ul>
                        <li>
                            <el-link :underline="false">
                                <router-link to="/pods">麦客PODS</router-link>
                            </el-link>
                        </li>
<!--                        <li>-->
<!--                            <el-link :underline="false">麦客WEBS</el-link>-->
<!--                        </li>-->
                        <li>
                            <el-link :underline="false">麦客Scos</el-link>
                        </li>
                        <li>
                            <el-link :underline="false">TAOPIX</el-link>
                        </li>
                        <li>
                            <el-link :underline="false">
                                <router-link to="/photobookai">Photobook.ai</router-link>
                            </el-link>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="5">
                    <a href="javascript: void(0);" class="footer-title">支持及下载</a>
                    <ul>
<!--                        <li>-->
<!--                            <el-link :underline="false">24小时支援</el-link>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <el-link :underline="false">知识库</el-link>-->
<!--                        </li>-->
                        <li>
                            <el-link :underline="false">
                                <router-link to="/downloads">支持及下载</router-link>
                            </el-link>
                        </li>
<!--                        <li>-->
<!--                            <el-link :underline="false">API</el-link>-->
<!--                        </li>-->
                    </ul>
                </el-col>
                <el-col :span="5">
                    <a href="javascript: void(0);" class="footer-title">文档中心</a>
                    <ul>
                        <li>
                            <el-link :underline="false">知识库</el-link>
                        </li>
                        <li>
                            <el-link :underline="false">API</el-link>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="5">
                    <a href="javascript: void(0);" class="footer-title">关于我们</a>
                    <ul>
                        <li>
                            <el-link :underline="false">
                                <router-link to="/about">关于麦客</router-link>
                            </el-link>
                        </li>
                        <li>
                            <el-link :underline="false">新闻</el-link>
                        </li>
                        <li>
                            <el-link :underline="false">客户评价</el-link>
                        </li>
                        <li>
                            <el-link :underline="false">加入麦客</el-link>
                        </li>
                    </ul>
                </el-col>
                <el-col :span="4" class="qr-code">
                    <div class="footer-img">
                        <div class="footer-weChat-icon">
                            <img class="weChat-icon" src="../assets/images/maikeos-wechat.jpg" alt="">
                        </div>
                        <span>麦客OS微信公众号</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="footer-copyright">
            <span>©&nbsp;2019 maikeos.com&nbsp;&nbsp;杭州麦客网科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <a target="_blank" href="https://beian.miit.gov.cn/">浙ICP备18014151号-1</a>
            <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img style="height: 16px;position: relative;top: 3px;margin-right: 4px" src="../assets/images/ghs.png" alt="">
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602010641">浙公安网备 33010602010641号</a>
            </span>
        </div>
    </footer>
</template>

<script>

    import Vue from 'vue';
    import { Link, Col, Row } from 'element-ui';

    Vue.use(Link);
    Vue.use(Row);
    Vue.use(Col);

    export default {
        props: {},
        data () {
            const footerList = [
                {
                    item: '',
                    list: [
                        {
                            id: '',
                            name: ''
                        }
                    ]
                }
            ];
            return {
                footerList
            }
        },
        created () {},
        mounted () {},
        watch: {},
        computed: {},
        methods: {},
        components: {},
    }
</script>

<style lang="scss">
    .site-footer .footer {
        position: relative;
        z-index: 110;
        width: 100%;
        padding: 32px 0;
        background-color: #fafafa;
    }
    .site-footer .footer .el-row {
        width: 1280px;
        margin: 0 auto;
    }
    .footer-img {
        width: 150px;
        height: auto;
        float: right;
        img {
            width: 150px;
        }
    }
    .footer-weChat-icon {
        width: 150px;
        height: 150px;
        background-color: #e8e8e8;
    }
    .qr-code {
        text-align: center;
    }
    .footer-title {
        font-size: 18px;
    }
    .site-footer ul {
        padding-left: 0;
        padding-top: 12px;
        list-style-type: none;
    }
    .site-footer {
        ul {
            li {
                line-height: 28px;
            }
        }
    }
    .footer-copyright {
        height: 48px;
        line-height: 48px;
        text-align: center;
        background-color: #fafafa;
        border-top: 1px solid #e8e8e8;
    }

</style>