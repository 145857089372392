import enLocale from 'element-ui/lib/locale/lang/en'

export default {
	Language: "English",
	Title: {
		PageNotFound: '404',
		Home: 'MaikeOS',
		Webs: 'WEBS',
		Pods: 'PODS',
		PhotoBookAi: 'Photobook.ai',
		Downloads: 'Downloads',
		About: 'About',
		SignIn: 'Sign in',
		SignUp: 'Sign up',
		Console: 'Console',
	},
	Navigation: {
		GoBack: 'Back'
	},
	Components: {
		Header: {
			Nav1: 'Products',
			Nav2: 'Maike PODS',
			Nav3: 'Downloads',
			Nav4: 'About',
			Nav5: 'Console',
			ActionSignIn: 'Sign in',
			ActionSignOut: 'Sign out',
		},
		ConsoleMenu: {
			Menu1: 'Application',
		}
	},
	...enLocale
}