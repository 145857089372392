import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import axios from 'axios'
// import qs from 'qs';
import { Message } from 'element-ui'

import VueI18n from 'vue-i18n'
// import messages from './locales/lang/zh_CN'
import zh_CN from './locales/lang/zh_CN'
import en from './locales/lang/en'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import enLocale from 'element-ui/lib/locale/lang/en'
import { Loading } from 'element-ui'

import { set, get, remove } from './cookie'
Vue.prototype.$cookie = { set, get, remove }
// import {
//     Row, Col,
//     Button, Form, FormItem, Input, InputNumber,
//     Select, Option,
//     Checkbox, CheckboxButton, Radio, RadioGroup,
//     Dropdown, DropdownItem, DropdownMenu,
//     Message, MessageBox, Loading, Alert, Notification
// } from 'element-ui'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
//
// NProgress.configure({ showSpinner: false })

import './sass/oxo.scss'

Vue.config.productionTip = false

// console.log(process.env)
// axios.defaults.baseURL = process.env.VUE_APP_API_TARGET

axios.defaults.timeout = 10000
axios.defaults.withCredentials = true
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Content-Type'] = 'application/json'

// const AXRequest = {
//     get (url, params) {
//         return axios.get(url, {params}).then().catch()
//     },
//     post (url, params) {
//         return axios.post(url, {params}).then().catch(e => {console.log(e)})
//     }
// }


// Vue.prototype.$api = api;
// Vue.prototype.$axios = axios;
// import Http from './http'


// Vue.use(Row)
// Vue.use(Col)
// Vue.use(Button)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
// Vue.use(InputNumber)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(Checkbox)
// Vue.use(CheckboxButton)
// Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(Dropdown)
// Vue.use(DropdownItem)
// Vue.use(DropdownMenu)
// Vue.use(Alert);
//
// Vue.use(Loading.directive);
//
// Vue.prototype.$loading = Loading.service;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
// Vue.prototype.$message = Message;

Vue.use(VueI18n)

let initLocale

if (localStorage.getItem('locale')) {

    initLocale = localStorage.getItem('locale')

} else {

    const userLanguage = (navigator.browserLanguage || navigator.language).toLowerCase()

    if (userLanguage.indexOf('zh') > - 1) {
        initLocale = 'zh_CN'
    } else {
        initLocale = 'en'
    }
    localStorage.setItem('locale', initLocale)
}

const i18n = new VueI18n({
    locale: initLocale,         // 语言标识
    fallbackLocale: 'zh_CN',    // 没有英文的时候默认中文语言
    silentFallbackWarn: true,   // 抑制警告
    //this.$i18n.locale         // 通过切换 locale 的值来实现语言切换
    messages: {
        'zh_CN': zh_CN,
        'en': en,
    }
})

let i18nMessages
if (initLocale === 'en') {
    i18nMessages = en
    Vue.use(ElementUI, { enLocale })
} else {
    i18nMessages = zh_CN
    Vue.use(ElementUI)
}


export function loadLanguageDefault(langPack) {
    return import(/* webpackChunkName: "lang-zh_CN-[request]" */ `./locales/lang/zh_CN/${langPack}`).then(msgs => {
        i18n.mergeLocaleMessage('zh_CN', msgs.default)
    }).catch(e => {})
}

export function loadLanguageAsync (lang, langPack) {
    // if (i18n.locale !== lang) {
        // if (!loadedLanguages.includes(lang)) {
        //     return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`).then(msgs => {
        //         i18n.setLocaleMessage(lang, msgs.default)
        //         loadedLanguages.push(lang)
        //         return setI18nLanguage(lang)
        //     })
        // }
        // return Promise.resolve(setI18nLanguage(lang))
    // console.log(i18n.messages)

    return import(/* webpackChunkName: "lang-[request]" */ `./locales/lang/${lang}/${langPack}`).then(msgs => {
        i18n.mergeLocaleMessage(lang, msgs.default)
        i18n.locale = lang
    }).catch(e => {})

        // return Promise.resolve(lang)
    // }
    // return Promise.resolve(lang)
}

// window.$i18n = i18n

// export function post (url, data) {
//     return new Promise((resolve, reject) => {
//         axios.post(url, data).then(res => {
//             resolve(res)
//         }, err => {
//             // reject(err)
//         })
//     })
// }
//
// export function get (url, data) {
//     return new Promise((resolve, reject) => {
//         axios.get(url, {
//             data: data
//         }).then(res => {
//             resolve(res)
//         }, err => {
//             // reject(err)
//         })
//     })
// }

Vue.prototype.$axios = axios
// Vue.prototype.$post = post
// Vue.prototype.$get = get

let auth = false
router.beforeEach((to, from, next) => {
    // NProgress.start()

    // const xLocale = localStorage.getItem('locale') ? localStorage.getItem('locale') : i18n.locale

    // loadLanguageAsync(initLocale, to.meta.langPack)

    if (! auth) { // 首次访检测状态

        axios.get('/auth')
            .then(res => {
                auth = true
                if (to.meta.loginRequired && ! res.loggedIn) {
                    localStorage.removeItem('userInfo')
                    window.location.href = '/signin'
                } else {
                    loadLanguageDefault(to.meta.langPack).then(() => {
                        loadLanguageAsync(initLocale, to.meta.langPack).then(() => next())
                    })
                }
            }, error => {})

    } else {
        loadLanguageDefault(to.meta.langPack).then(() => {
            loadLanguageAsync(initLocale, to.meta.langPack).then(() => next())
        })
    }


    // if (to.meta.loginRequired) {
    //
    //     axios.get('/con')
    //         .then(res => {
    //
    //             loadLanguageDefault(to.meta.langPack).then(() => {
    //                 loadLanguageAsync(initLocale, to.meta.langPack).then(() => next())
    //             })
    //         }, error => {})
    //
    // } else {
    //
    //     loadLanguageDefault(to.meta.langPack).then(() => {
    //         loadLanguageAsync(initLocale, to.meta.langPack).then(() => next())
    //     })
    // }

    if (to.meta.title) {

        document.title =
            to.meta.title === 'Home'
                ? i18nMessages.Title.Home
                : i18nMessages.Title[to.meta.title] + ' - ' + i18nMessages.Title.Home
    }

    // axios.get('/api/userInfo')
    //     .then(res => {
    //
    //         localStorage.setItem('userInfo', JSON.stringify(res.userInfo))
    //
    //         if (to.meta.loginRequired && ! res.userInfo.isLogin) {
    //             // window.location.href = '/signin'
    //             next('/signin')
    //         } else {
    //
    //             if (to.meta.title) {
    //
    //                 document.title =
    //                     to.meta.title === 'Home'
    //                         ? i18nMessages.Title.Home
    //                         : i18nMessages.Title[to.meta.title] + ' - ' + i18nMessages.Title.Home
    //             }
    //
    //             next()
    //         }
    //     }, err => {})
})

router.afterEach(() => {

    // NProgress.done()
})

let loadingInstance = false;
axios.interceptors.request.use(request => {

    // console.log(request)

    request.headers['Os-Locale'] = initLocale

    request.url = '/api' + request.url

    if (request.data && request.data !== 'undefined') {
        if (request.data.fullscreenLoading && request.data.fullscreenLoading !== 'undefined') {
            delete request.data.fullscreenLoading
            loadingInstance = Loading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0)'
            })
        }
    }

    return request
})

axios.interceptors.response.use(
    response => {

        if (loadingInstance) loadingInstance.close()

        // if (response.data.hasOwnProperty('code') && response.data.code === 'SUCCESS') {
        //
        //     // return Promise.resolve(response.data)
        //
        // } else {
        //
        //     if (response.data.hasOwnProperty('msg')) {
        //         Message.error(response.data.msg)
        //     } else {
        //         console.log(response.data)
        //         Message.error(i18n.t('Hint.ServerError'))
        //     }
        //
        //     // return Promise.reject(response.data)
        // }

        if (response.data.code !== 'SUCCESS') {
            Message.error(response.data.msg)
            return Promise.reject(response.data)
        }

        return Promise.resolve(response.data)
    },
    error => {

        if (loadingInstance) loadingInstance.close()

        if (error.response.status === 401 || error.response.status === 419) {
            // localStorage.removeItem('userInfo')
            // window.location.href = '/signin'
            window.location.reload()
        } else {
            Message.error(i18n.t('Hint.ServerError'))
        }
        return Promise.reject(error)
    }
)


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('custom-render-trigger'))
    }
}).$mount('#app')




