import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const xmeta = {
    footer: true,
    fullHeader: false,
}

const control = {
    title: 'Console',
    footer: false,
    fullHeader: true,
    consoleMenu: true,
    loginRequired: true,
    displayUserInfo: true,
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '*',
            meta: {
                title: 'PageNotFound',
                ...xmeta,
            },
            component: () => import(/* webpackChunkName: "nopage" */ '../components/PageNotFound')
        },
        {
            path: '/',
            name: 'Home',
            meta: {
                title: 'Home',
                ...xmeta
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/Home')
        },
        {
            path: '/signin',
            name: 'SignIn',
            meta: {
                title: 'SignIn',
                langPack: 'Auth',
                ...xmeta,
                footer: false,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/SignIn')
        },

        {
            path: '/signup',
            name: 'SignUp',
            meta: {
                title: 'SignUp',
                langPack: 'Auth',
                ...xmeta,
                footer: false,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/SignUp')
        },
        {
            path: '/pods',
            name: 'ProductPods',
            meta: {
                title: 'Pods',
                ...xmeta,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/Pods')
        },
        {
            path: '/photobookai',
            name: 'PhotobookAi',
            meta: {
                title: 'PhotoBookAi',
                ...xmeta,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/PhotobookAi')
        },
        {
            path: '/downloads',
            name: 'Downloads',
            meta: {
                title: 'Downloads',
                ...xmeta,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/Downloads')
        },
        {
            path: '/about',
            name: 'About',
            meta: {
                title: 'About',
                ...xmeta,
            },
            component: () => import(/* webpackChunkName: "main" */ '../views/About')
        },
        {
            path: '/console',
            name: 'Console',
            meta: {
                ...control,
            },
            component: () => import(/* webpackChunkName: "console" */ '../views/Console'),
            redirect: '/console/pods',
            children: [
                {
                    path: 'pods',
                    name: 'Pods',
                    meta: {
                        langPack: 'Application',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "application" */ '../views/Console/App/Pods')
                },
                {
                    path: 'pods/create',
                    name: 'PodsCreate',
                    meta: {
                        langPack: 'Application',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "application" */ '../views/Console/App/PodsCreate')
                },
                {
                    path: 'app/renew/:back/:appCode',
                    name: 'AppRenew',
                    meta: {
                        langPack: 'Application',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "application" */ '../views/Console/App/Renew')
                },
                {
                    path: 'order',
                    name: 'Order',
                    meta: {
                        langPack: 'Order',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "order" */ '../views/Console/Account/Order')
                },
                {
                    path: 'order/detail/:orderNo',
                    name: 'OrderDetail',
                    meta: {
                        langPack: 'Order',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "order" */ '../views/Console/Account/OrderDetail')
                },
                {
                    path: 'order/pay/:orderNo',
                    name: 'OrderPay',
                    meta: {
                        langPack: 'Account',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "order" */ '../views/Console/Account/OrderPay')
                },
                {
                    path: 'baseinfo',
                    name: 'BaseInfo',
                    meta: {
                        langPack: 'Auth',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "info" */ '../views/Console/Info/BaseInfo')
                },
                {
                    path: 'modifyPassword',
                    name: 'ModifyPassword',
                    meta: {
                        langPack: 'Auth',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "info" */ '../views/Console/Info/ModifyPassword')
                },
                {
                    path: 'expense',
                    name: 'Expense',
                    meta: {
                        langPack: 'Account',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "order" */ '../views/Console/Account/Expense')
                },
                {
                    path: 'transaction',
                    name: 'Transaction',
                    meta: {
                        langPack: 'Account',
                        ...control,
                    },
                    component: () => import(/* webpackChunkName: "order" */ '../views/Console/Account/Transaction')
                },
            ]
        },
    ]
})
