export default {
	Language: '简体中文',
	Title: {
		PageNotFound: '404',
		Home: '麦客网',
		Webs: 'WEBS',
		Pods: 'PODS',
		PhotoBookAi: 'Photobook.ai',
		Downloads: '支持及下载',
		About: '关于麦客',
		SignIn: '登录',
		SignUp: '注册',
		Console: '控制台',
	},
	Navigation: {
		GoBack: '返回'
	},
	Hint: {
		NetworkError: '网络不给力 T_T, 请稍后再试',
		ServerError: '服务器异常 T_T, 请稍后再试',
		SubmitSuccess: '提交成功',
		ModifySuccess: '修改成功',
		DeleteSuccess: '删除成功',
		CreateSuccess: '创建成功',
		Loading: '正在加载...',
	},
	Action: {
		Confirm: '确 定',
		Cancel: '取 消',
	},
	Components: {
		Header: {
			Nav1: '产品',
			Nav1_1: '麦客PODS',
			Nav1_2: 'Photobook.ai',
			Nav3: '支持及下载',
			Nav4: '关于我们',
			Nav5: '控制台',
			ActionSignIn: '登录',
			ActionSignOut: '退出',
		},
		ConsoleMenu: {
			Menu1: '应用中心',
			Menu1_1: 'PODS',
			Menu2: '用户中心',
			Menu2_1: '基本信息',
			Menu2_2: '修改密码',
			Menu3: '费用',
			Menu3_1: '订单',
			Menu3_2: '账单',
			Menu3_3: '收支明细',
		}
	},
}
